.header-wrapper {
    background: linear-gradient(rgba(14,41,85,.63), rgba(14,41,85,.23));
    background-color: #000;
    box-sizing: border-box;
    padding: 16px 16px;
    height: 70px;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
        font-size: 0;
        margin-right: 200px;
    }
    .link {
        font-size: 18px;
        color: #fff;
        text-decoration: none;
        margin-right: 24px;
        &:hover {
            // color: rgb(9, 211, 172);
            color: rgb(255, 78, 25);
        }
        &.selected {
            color: rgb(255, 78, 25);
        }
    }
}
@media (max-width: 768px) {
    .header-wrapper {
        padding: 16px 0px;
        .logo {
            margin-right: 10px;
        }
        .link {
            font-size: 10px;
        }
    }
}

.dashboard {
    margin-top: 12px;
    width: 100%;
    height: 158px;
    border-radius: 24px;
    background: linear-gradient(rgba(14,41,85,.63), rgba(14,41,85,.23));
    display: flex;
    text-align: center;
    padding-bottom: 25px;
    box-sizing: border-box;
}
.hot-label {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 6px;
    position: absolute;
    height: 12px;
    line-height: 12px;
    right: 0;
    top: 0;
    color: #fff;
    font-size: 8px;
    background-color: red;
}
.coming-label {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 6px;
    position: absolute;
    height: 12px;
    right: -70px;
    top: 0;
    color: #fff;
    font-size: 10px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

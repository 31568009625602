body {
  margin: 0;
  background: linear-gradient(rgba(14,41,85,.63), rgba(14,41,85,.23));
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-resizable {
  max-width: 100%;
}

.react-resizable-handle {
  bottom: -10px;
  right: -10px;
}

/* chrome */ 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
}
/* 火狐浏览器 */
input[type="number"]{ 
    -moz-appearance: textfield; 
}